export class Pdf {
    constructor(

      public vvh460Dicsub02D208: string,
      public vvh460DocLetra: string,
      public vvh460DocPvta: number,
      public vvh460DocNroco: number,
      public vvh460PdfDoc: string,
    ) {}
  
      /** Creates a new document either with default values or the values of the object passed in
     *
     * @param {Pdf} pdf information to copy into new document object
    */
  }