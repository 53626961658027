import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { LazyLoadEvent, Message, SelectItem } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Document } from '../models/document.model';
import { Pdf } from '../models/pdf.model';
import { vehicleVinResponseTO } from "../resolves/vehicle-vin-responseTO";
import { VehicleService } from "../services/vehicle.service";
import { DocumentService } from './../services/document.service';



@Component({
	selector: 'app-documents-search',
	templateUrl: './documents-search.component.html',
	styleUrls: ['./documents-search.component.css']
})
export class DocumentsSearchComponent implements OnInit {
	documentSearchCriteria: FormGroup = new FormGroup({
		documentOrigin: new FormControl(''),
		documentType: new FormControl(''),
		documentLetter: new FormControl(''),
		documentNumber: new FormControl(''),
		documentSalesPoint: new FormControl(''),
		documentDateFrom: new FormControl(''),
		documentDateTo: new FormControl(''),
		documentVinNumber: new FormControl(''),
		cuitNumber: new FormControl(''),
	});
	documentList: Document[];
	pdfs: Pdf[]
	selectedDocuments: Document[] = [];
	colums: any[];
	optionsOrigin: SelectItem[];
	onsearch: boolean;
	onsearchDownload: boolean = false;
	msg: Message[] = [];
	DateValid: boolean;
	params: HttpParams | undefined;
	totalOfDocs: number;
	myMap: Map<string, string>;
	vins: String[] = [];
	vinSelected: string | undefined;
	serial: string;
	@ViewChild('documentsTable') documentsTable: any;
	retailReportsError: any;
	isCheck: boolean | undefined;
	isIntern: boolean = false;

	constructor(private documentService: DocumentService, private datepipe: DatePipe, private authService: AuthService, private vehicleService: VehicleService) {
		this.documentList = [];
		this.vins = [];
		this.msg = [{
			severity: 'warn',
			summary: 'Margen de fechas demasiado extenso. ',
			detail: 'Debes realizar Busquedas en un plazo de tiempo mas corto'
		}];
		this.DateValid = false;
		this.myMap = new Map();
		this.colums = [
			{field: 'vvh460Origen', header: 'Origen del documento', filterMatchMode: 'contains'},
			{field: 'vvh460Dicsub02D208', header: 'Tipo de documento', filterMatchMode: 'contains'},
			{field: 'vvh460DocLetra', header: 'Letra del documento', filterMatchMode: 'contains'},
			{field: ' vvh460DocPvta', header: 'Punto de venta', filterMatchMode: 'contains'},
			{field: 'vvh460DocNroco', header: 'Nro del documento', filterMatchMode: 'contains'},
			{field: 'vvh460FemisDoc', header: 'Fecha de emisión', filterMatchMode: 'contains'},
			{field: 'vvh460DocNroco', header: 'Número de vin', filterMatchMode: 'contains'},
			{field: 'vvh402Cuit', header: 'CUIT', filterMatchMode: 'contains'},
			{field: '', header: 'PDF', filterMatchMode: 'contains'},
		];
		this.optionsOrigin = [
			{label: 'Vehículos', value: 'VHARG'},
			{label: 'Repuestos', value: 'PAARG'}
		];
		this.onsearch = false;
		this.totalOfDocs = 0;
		this.serial = ""
		if (this.authService.getClient().code == 5 && this.authService.getClient().type == 'I') {
			this.isIntern = true;
		}
	}

	downloadPdf(doc: Document) {
		this.documentService.getpdf(doc.vvh460Dicsub02D208, doc.vvh460DocLetra, doc.vvh460DocNroco, doc.vvh460DocPvta).subscribe({
			next: (pdfs: Pdf[]) => {
				let pdf = pdfs[0];
				let a = document.createElement("a");
				a.href = 'data:application/octet-stream;base64,' + pdf.vvh460PdfDoc;
				if (doc.vvh402Cuit == null || doc.vvh402Cuit == 0) {
					a.download = doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco + '.pdf';
				} else {
					a.download = doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco + "_" + doc.vvh402Cuit + '.pdf';
				}
				a.click();
			},
			error: (error) => {
				console.error('Error al obtener el archivo PDF:', error);
			}
		});
	}

	downloadZipPdf() {
    let zip = new JSZip();
    let checkbox = document.getElementById("globalChek") as HTMLInputElement;
    let promises: Promise<void>[] = [];
    
    this.selectedDocuments.forEach((doc: Document) => {
        let promise = new Promise<void>((resolve, reject) => {
            this.documentService.getpdf(doc.vvh460Dicsub02D208, doc.vvh460DocLetra, doc.vvh460DocNroco, doc.vvh460DocPvta).subscribe({
                next: (pdfs: Pdf[]) => {
                    let pdf = pdfs[0];
                    const blob = this.base64ToBlob(pdf.vvh460PdfDoc);
                    let fileName = doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco;
                    if (doc.vvh402Cuit != null && doc.vvh402Cuit != 0) {
                        if (doc.vvh002NroVin != null && doc.vvh002NroVin != '') {
                            fileName += "" + doc.vvh402Cuit + "" + doc.vvh002NroVin;
                        } else {
                            fileName += "_" + doc.vvh402Cuit;
                        }
                    }
                    fileName += ".pdf";
                    zip.file(fileName, blob, { binary: true });
                    resolve();
                },
                error: (error) => {
                    console.error('Error al obtener los archivos PDF:', error);
                    reject();
                }
            });
        });
        promises.push(promise);
    });

    Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'documentos.zip');
        });
        this.selectedDocuments = [];
        checkbox.checked = false;
    });
}

	getSizeDocumentsList() {
		this.getParams(this.documentSearchCriteria);
		this.documentService.getTotalRecords(this.myMap).subscribe(d => {
			this.totalOfDocs = d.totalRecords;
		});
	}

	getSizeList(params: Map<string, string>) {
		this.documentService.getTotalRecords(params).subscribe(d => {
			this.totalOfDocs = d.totalRecords;
		});
	}

	getTypeOfDoc(doc: Document) {
		if (doc.vvh460Origen === 'VHARG') {
			return 'Vehiculos';
		}
		return 'Repuestos';
	}

	base64ToBlob(doc: string) {
		const binStr = atob(doc);
		const len = binStr.length;
		const arr = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			arr[i] = binStr.charCodeAt(i);
		}
		//return new Blob([arr], { type: 'application/pdf' });
		return new Blob([arr], {type: 'application/pdf'});
	}

	showPdf(doc: Document) {
		this.documentService.getpdf(doc.vvh460Dicsub02D208, doc.vvh460DocLetra, doc.vvh460DocNroco, doc.vvh460DocPvta).subscribe({
			next: (pdfs: Pdf[]) => {
				const blob = this.base64ToBlob(pdfs[0].vvh460PdfDoc);
				const url = URL.createObjectURL(blob);
				const pdfWindow = window.open('');
				pdfWindow!.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
				pdfWindow!.document.close();
			},
			error: (error) => {
				console.error('Error al obtener el archivo PDF:', error);
			}
		});
	}

	isDateValid(documentSearchCriteria: any): boolean {
		let datetoParsed = this.datepipe.transform(documentSearchCriteria.documentDateTo, 'yyyy-MM-dd');
		let dateFromParsed = this.datepipe.transform(documentSearchCriteria.documentDateFrom, 'yyyy-MM-dd');
		var difference_In_Time = this.date_diff_indays(dateFromParsed, datetoParsed);
		if (difference_In_Time > 65) {
			this.DateValid = true;
			return false;
		} else {
			this.DateValid = false;

		}

		return true;

	}

	date_diff_indays(date1: any, date2: any) {
		let dt1 = new Date(date1);
		let dt2 = new Date(date2);
		return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
	}


	getParams(documentSearchCriteria: any) {

		this.myMap.set('vvh460CodConce', this.authService.getClient().code.toString());

		if (documentSearchCriteria.documentType !== undefined && documentSearchCriteria.documentType !== null && documentSearchCriteria.documentType !== '') {
			this.myMap.set('vvh460Dicsub02D208', String(documentSearchCriteria.documentType).toUpperCase());
		}
		if (documentSearchCriteria.documentLetter !== undefined && documentSearchCriteria.documentLetter !== null && documentSearchCriteria.documentLetter !== '') {
			this.myMap.set('vvh460DocLetra', String(documentSearchCriteria.documentLetter).toUpperCase());
		}
		if ((documentSearchCriteria.documentNumber !== '') && (documentSearchCriteria.documentNumber !== null) && documentSearchCriteria.documentNumber !== undefined) {
			this.myMap.set('vvh460DocNroco', documentSearchCriteria.documentNumber);
		}
		if ((documentSearchCriteria.cuitNumber !== '') && (documentSearchCriteria.cuitNumber !== null) && documentSearchCriteria.cuitNumber !== undefined) {
			this.myMap.set('vvh402Cuit', documentSearchCriteria.cuitNumber.replace(/-/g, ""));
		}
		if ((documentSearchCriteria.documentSalesPoint !== '') && (documentSearchCriteria.documentSalesPoint !== null) && documentSearchCriteria.documentSalesPoint !== undefined) {
			this.myMap.set('vvh460DocPvta', documentSearchCriteria.documentSalesPoint);
		}
		if ((documentSearchCriteria.documentDateFrom !== '') && (documentSearchCriteria.documentDateFrom !== null) && documentSearchCriteria.documentDateFrom !== undefined) {
			let dateFromParsed = this.datepipe.transform(documentSearchCriteria.documentDateFrom, 'yyyy-MM-dd') ?? '';
			this.myMap.set('vvh460FemisDocDesde', dateFromParsed);
		}
		if ((documentSearchCriteria.documentDateTo !== '') && (documentSearchCriteria.documentDateTo !== null) && documentSearchCriteria.documentDateTo !== undefined) {
			let datetoParsed = this.datepipe.transform(documentSearchCriteria.documentDateTo, 'yyyy-MM-dd') ?? '';
			this.myMap.set('vvh460FemisDocHasta', datetoParsed);

		}
		if ((documentSearchCriteria.documentVinNumber !== '') && (documentSearchCriteria.documentVinNumber !== null) && documentSearchCriteria.documentVinNumber !== undefined) {
			documentSearchCriteria.vin = this.vinSelected
			this.myMap.set('vvh002NroVin', documentSearchCriteria.vin);
		}
		if (documentSearchCriteria.documentOrigin !== null && documentSearchCriteria.documentOrigin !== '' && documentSearchCriteria.documentOrigin !== undefined) {
			this.myMap.set('vvh460Origen', documentSearchCriteria.documentOrigin);
		}
		if (documentSearchCriteria.documentOrigin == null || documentSearchCriteria.documentOrigin == '' || documentSearchCriteria.documentOrigin == undefined) {
			this.myMap.set('vvh460Origen', 'VHARG');
		}
	}

	parseCUITCUIL(documentSearchCriteria: any) {
		let cuitNumber = documentSearchCriteria.replace("-", "");
		return cuitNumber;
	}

	parseOrigin(documentSearchCriteria: any) {
		let origin = documentSearchCriteria.set('VHARG');
		return origin;
	}

	onSubmit(documentSearchCriteria: any) {
		this.myMap.clear();
		this.documentList = [];
		this.getParams(documentSearchCriteria);
		this.getSizeList(this.myMap);
		this.loadInList(this.myMap, 0, this.documentsTable.rows);
	}

	getOnSearch() {
		return this.onsearch;
	}

	getOnSearchDownload() {
		return this.onsearchDownload;
	}

	loadData(event: LazyLoadEvent) {
		let lastRow: number = this.getLastRowIndex();
		if (this.authService)
		this.loadInList(this.myMap, event.first!, event.rows! + event.first!);
	}

	getLastRowIndex(): number {
		if (this.documentsTable.first + this.documentsTable.rows > this.documentsTable.totalRecords) {
			return this.documentsTable.totalRecords;
		} else {
			return this.documentsTable.first + this.documentsTable.rows;
		}
	}

	getRecords() {
		this.params = undefined;
		const actualDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
		const dateWithOffset = this.datepipe.transform(new Date().setDate(new Date().getDate() - 30), 'yyyy-MM-dd') ?? '';
		this.getParams(this.documentSearchCriteria);
		this.myMap.set('vvh460FemisDocDesde', dateWithOffset);
		this.myMap.set('vvh460FemisDocHasta', actualDate);

		this.loadInList(this.myMap, 0, 20);
	}

	loadInList(params: Map<string, string>, minval: number, max: number) {
		this.documentList = [];
		this.onsearch = true;
		if (this.authService.getClient().code == 5 && this.authService.getClient().type == 'I') {
			this.documentService.getDownload(params, minval, max).subscribe(
				d => {
					if (!(this.documentList.length > 0)) {
						(<Document[]>d).forEach(
							(document) => {
								this.documentList.push(document);
							}
						);
					}
					else if (this.documentList.length === 0 || this.documentList === null) {
						alert("No se han encontrado documentos para esos parámetros de búsqueda, por favor intente con otros parámetros.")
					}
					this.onsearch = false;
				}
			)
		} else {
			this.documentService.getfiltered(params, minval, max).subscribe(
				d => {
					if (!(this.documentList.length > 0)) {
						(<Document[]>d).forEach(
							(document) => {
								this.documentList.push(document);
							}
						);
					}
					else if (this.documentList.length === 0 || this.documentList === null) {
						alert("No se han encontrado documentos para esos parámetros de búsqueda, por favor intente con otros parámetros.")
					}
					this.onsearch = false;
				}
			)
		};
		this.onsearch = false;
		this.params = undefined;
	}

	resetFormGroup() {
		this.documentSearchCriteria.reset();
	}

	loadInitialRecords() {
		this.documentList = [];
		this.getRecords();
	}

	ngOnInit() {
			this.loadInitialRecords();
			this.getSizeDocumentsList();
	}

	massiveDownload() {
    this.onsearchDownload = true;
    let zip = new JSZip();
    let a;
    let doc: Document;
    for (doc of this.documentList) {
      this.documentService.getpdf(doc.vvh460Dicsub02D208, doc.vvh460DocLetra, doc.vvh460DocNroco, doc.vvh460DocPvta).subscribe({
				next: (pdfs: Pdf[]) => {
					const blob = this.base64ToBlob(pdfs[0].vvh460PdfDoc);
					if (doc.vvh402Cuit == null || doc.vvh402Cuit == 0) {
						zip.file(doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco + '.pdf', blob, {binary: true});
					} else {
						if (doc.vvh002NroVin != null && doc.vvh002NroVin != '') {
							zip.file(doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco + "" + doc.vvh402Cuit + "" + doc.vvh002NroVin + '.pdf', blob, {binary: true});
						} else {
							zip.file(doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco + "_" + doc.vvh402Cuit + '.pdf', blob, {binary: true});
						}
					}
				},
				error: (error) => {
					console.error('Error al obtener los archivos PDF:', error);
				}
			});
    this.onsearchDownload = false;
    zip.generateAsync({type: 'blob'}).then(function (content) {
      FileSaver.saveAs(content, 'documentos.zip');
    });
  }
}

	selectedAllDocuments() {
		let checkbox = document.getElementById("globalChek") as HTMLInputElement
		if (checkbox.checked) {
			this.selectedDocuments = this.documentList;
		} else
			this.selectedDocuments = [];
	}

	downloadDocumentosPagina() {
    let zip = new JSZip();

    const promises = this.selectedDocuments.map(doc => {
        return new Promise<void>((resolve, reject) => {
            this.documentService.getpdf(doc.vvh460Dicsub02D208, doc.vvh460DocLetra, doc.vvh460DocNroco, doc.vvh460DocPvta).subscribe({
                next: (pdfs: Pdf[]) => {
                    const pdf = pdfs[0];
                    if (pdf) {
                        const blob = this.base64ToBlob(pdf.vvh460PdfDoc);
                        let fileName = doc.vvh460Origen + "" + doc.vvh460Dicsub02D208 + "" + doc.vvh460DocLetra + "" + doc.vvh460DocPvta.toString() + "" + doc.vvh460DocNroco;
                        if (doc.vvh402Cuit != null && doc.vvh402Cuit != 0) {
                            if (doc.vvh002NroVin != null && doc.vvh002NroVin != '') {
                                fileName += "" + doc.vvh402Cuit + "" + doc.vvh002NroVin;
                            } else {
                                fileName += "_" + doc.vvh402Cuit;
                            }
                        }
                        fileName += ".pdf";
                        zip.file(fileName, blob, { binary: true });
                        resolve();
                    } else {
                        reject();
                    }
                },
                error: (error) => {
                    console.error('Error al obtener los archivos PDF:', error);
                    reject();
                }
            });
        });
    });

    Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'documentos.zip');
        });
        this.selectedDocuments = [];
        let checkbox = document.getElementById("globalChek") as HTMLInputElement;
        checkbox.checked = false;
    });
}

	getVINSforVehicle(vvh002NroVin: string) {
		if (vvh002NroVin.length > 3 && vvh002NroVin.length <= 8) {
			this.vehicleService.getVins(vvh002NroVin).subscribe((response: vehicleVinResponseTO) => {
				if (response.content.length != 0) {
					this.vins = [];
					for (let parameter of response.content) {
						this.vins.push(parameter)
					}
				}
			})
		}
	}
}