import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Pageable } from '../models/pageable.model';
import {vehicleVinResponseTO} from "../resolves/vehicle-vin-responseTO";
import { AuthService } from 'src/app/core/auth/auth.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class VehicleService {
	private vehicleUrl = environment.api + 'vehicles';

	private options = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
			//withCredentials: true
		})
	}

	/** Creates a new instance of the DetalleVentaService
	 *
	 * @param {HttpClient} http used for calls to the detalleVenta API
	 * @param {MessageService} messageService used to display success/failure messages
	 */
	constructor(private http: HttpClient, private messageService: MessageService,
				private authService: AuthService) {
	}

	/** gets all vehicles
	 * @param page
	 * @param pageSize
	 * @returns {Observable<Pageable>}
	 */
	get(page?: Number, pageSize?: Number): Observable<Pageable> {
		this.options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
				'Accept-Language': 'es'
			})
		}

		let params = '?client=' + this.authService.getClient().code;
		params += '&country=' + this.authService.getClient().country;
		params += pageSize ? '&size=' + pageSize : '';
		params += page ? '&page=' + page : '';
		//explicitly do not type this get, due to list structure returned
		return this.http.get<Pageable>(this.vehicleUrl + params, this.options);
	}

	getVins(vvh002NroVin: string): Observable<vehicleVinResponseTO> {
		this.options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
				'Accept-Language': 'es'
			})
		}

		let params = '?serial=' + vvh002NroVin;
		params += '&conEntrega=' + this.authService.getClient().code;

		return this.http.get<vehicleVinResponseTO>(this.vehicleUrl + "/vins" + params, this.options);
	}
}