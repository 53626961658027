import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from '../models/pageable.model';
import { VehicleService } from '../services/vehicle.service';

/** resolves the list of detalleVentas required for the DetalleVenta list page */
@Injectable()
export class VehicleListResolve{

	/** Creates a new instance of the DetalleVentaListResolve
	 *
	 * @param {VehicleService} vehicleService required to access the detalleVenta API
	 */
	constructor(private vehicleService: VehicleService) { }

	/** function to get detalleVenta data from the detalleVenta service and store it on the router
	 *
	 * @returns {Observable<HttpResponse<DetalleVenta[]>>} an observable list of all detalleVentas
	 */
	resolve(): Observable<Pageable> {
		return this.vehicleService.get(0, 10);
	}
}
