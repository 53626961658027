import { Role } from "./role.model";

export class Client {

    code: number;
    country: string;
    type: string;
    description: string;
    roles: Role[];

	constructor(client?: Client) {

        this.code = 0;
        this.country = '';
        this.type = '';
        this.description = '';
        this.roles = [];
        
        if(client) {
            Object.assign(client);
        }
        
    }

}