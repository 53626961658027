import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RetailResponse } from '../resolves/retailResponse';
import { Invoice } from '../models/invoice.model';
import { AuthService } from 'src/app/core/auth/auth.service';

/** This service is for calls to the detalleVenta API */
@Injectable()
export class InvoiceService {

    private invoiceUrl = environment.api + 'invoice';

    /** Options for API calls, headers are handled in the HttpInterceptor */
    private options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
            //withCredentials: true
        })
    }

	/** Creates a new instance of the DetalleVentaService
	 *
	 * @param {HttpClient} http used for calls to the detalleVenta API
	 * @param {CurrentUser} currentUser used to display success/failure messages
	 */
    constructor(private http: HttpClient, private authService: AuthService) { }

	/**
	 * 
	 * @param retails 
	 */
    addRetail(retails: Invoice[]): Observable<RetailResponse[]> {
        this.options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Client': this.authService.getClient().code.toString(),
                'Country': this.authService.getClient().country,
				'Accept-Language': 'es'
            })
        }

        // @ts-ignore
        return this.http.post<Retail>(this.invoiceUrl, retails, this.options);

    }
}
