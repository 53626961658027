/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	ssoEndpointUri: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize/wia',
  ssoClientId: 'urn:arfd:pcf:native:qa',
  ssoResourceId: 'urn:arfd:pcf:weblogin:qa',
  api: 'https://api.qa01e.gcp.ford.com/api_arfd/api/v1/',
	logoutUrl: 'https://wwwqa.faust.idp.ford.com/logout'
};
