import { Component } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  template: ` <div *ngIf="this.loaderService.shouldShow()">
    <div class="loading">Loading&#8230;{{this.loaderService.shouldShow()}}</div>
  </div>`,
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) {}
}
