import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { RetailReportsErrorHandler } from './retailReportsErrorHandler';

@Component({
  selector: 'app-landing-page',
  template: 'Home',
})
export class LandingPageComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly errorHandler: RetailReportsErrorHandler
  ) {}

  ngOnInit(): void {
    const firstEnabled = this.authService.navMenuItems.find((e) => e.visible);

    if (firstEnabled) {
      this.router.navigate([firstEnabled.routerLink]);
    } else {
      this.errorHandler.handleError({ status: 700 });
    }
  }
}
