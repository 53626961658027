import { type } from "os";
import { clearScreenDown } from "readline";

export class SparePartModel {
	openingDate: Date | undefined | null;
	closingDate: Date | undefined | null;
	generatingDate!: string;
  generatingTime!: string;
	status!: boolean;

  generatingMix?: Date | undefined | null;

	constructor(sparePartModel?: SparePartModel) {
		if (sparePartModel) {
      this.openingDate = sparePartModel.openingDate ? new Date(sparePartModel.openingDate) : undefined;
      this.closingDate = sparePartModel.closingDate ? new Date(sparePartModel.closingDate) : undefined;
      this.generatingDate = sparePartModel.generatingDate;
      this.generatingTime = sparePartModel.generatingTime;
      this.status = sparePartModel.status;

      const gDate = new Date();
      gDate.setDate(+this.generatingDate);

      this.generatingMix = gDate;
		}
	}

  format() {
    if (this.generatingMix) {
      const hours = this.generatingMix.getHours().toString().padStart(2, '0');
      const minutes = this.generatingMix.getMinutes().toString().padStart(2, '0');
      const seconds = this.generatingMix.getSeconds().toString().padStart(2, '0');
      const days = parseInt(this.generatingMix.toLocaleDateString('en-US', {day: '2-digit'}),10);
      this.generatingDate = days.toString()
      this.generatingTime = `${hours}:${minutes}:${seconds}`;
      delete this.generatingMix;
    }
    
    return this;
  }
}
