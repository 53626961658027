import {TotalDocs} from './../models/totalDocs.model';
import {Document} from './../models/document.model';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import {Pdf} from './../models/pdf.model';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {
	documents: Document[];
	pdf: Pdf[]
	private documentUrl = environment.api + 'documents';

	private options = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	};

	constructor(private http: HttpClient, private authService: AuthService) {
		this.documents = [];
	}

	getfiltered(httpparamsMap: Map<string, string>, minValue: number, maxValue: number) {
		let httpParams = new HttpParams();
		httpparamsMap.forEach((value: string, key: string) => {
			httpParams = httpParams.append(key, value);
		});
		httpParams = httpParams.append('minRow', minValue.toString());
		httpParams = httpParams.append('maxRow', maxValue.toString());

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParams
		};

		return this.http.get<Document[]>(this.documentUrl + '/files', httpOptions).pipe(
			map((data: any[]) => data.map((item: any) => new Document(item.vvh002NroVin, item.vvh402Cuit, item.vvh460Origen, item.vvh460Dicsub02D208,
					item.vvh460DocLetra, item.vvh460DocPvta, item.vvh460DocNroco, item.vvh460FemisDoc,
					item.vvh460Status, item.vvh460PdfDoc)
				)
			), catchError(this.handleError)
		);
	}

	getDownload(httpparamsMap: Map<string, string>, minValue: number, maxValue: number) {
		let httpParams = new HttpParams();
		httpparamsMap.forEach((value: string, key: string) => {
			httpParams = httpParams.append(key, value);
		});
		httpParams = httpParams.append('minRow', minValue.toString());
		httpParams = httpParams.append('maxRow', maxValue.toString());

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParams
		};

		return this.http.get<Document[]>(this.documentUrl + '/filesDownload', httpOptions).pipe(
			map((data: any[]) => data.map((item: any) => new Document(item.vvh002NroVin, item.vvh402Cuit, item.vvh460Origen, item.vvh460Dicsub02D208,
					item.vvh460DocLetra, item.vvh460DocPvta, item.vvh460DocNroco, item.vvh460FemisDoc,
					item.vvh460Status, item.vvh460PdfDoc)
				)
			), catchError(this.handleError)
		);
	}

	handleError(error: any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `No se han encontrado documentos para esos parámetros de búsqueda, por favor intente con otros parámetros.`;
		} else {
			// server-side error
			errorMessage = `No se han encontrado documentos para esos parámetros de búsqueda, por favor intente con otros parámetros.`;
		}
		window.alert(errorMessage);
		return throwError(errorMessage);
	}

	getTotalRecords(httpparamsMap: Map<string, string>): Observable<TotalDocs> {

		let httpParams = new HttpParams();
		httpparamsMap.forEach((value: string, key: string) => {
			httpParams = httpParams.append(key, value);
		});
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParams
		};
		const url = this.documentUrl + '/amount';

		return this.http.get<TotalDocs>(url, httpOptions).pipe(
			map((data: any) => new TotalDocs(data.totalRecords)));
	}

	getpdf(vvh460Dicsub02D208: string, vvh460DocLetra: string, vvh460DocNroco: number, vvh460DocPvta: number){
    
    let httpParams = new HttpParams();
	
		httpParams = httpParams.append('vvh460DocNroco', vvh460DocNroco.toString());
		httpParams = httpParams.append('vvh460DocPvta', vvh460DocPvta.toString());
		httpParams = httpParams.append('vvh460Dicsub02D208', vvh460Dicsub02D208);
		httpParams = httpParams.append('vvh460DocLetra', vvh460DocLetra);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParams
		};

		return this.http.get<Pdf[]>(this.documentUrl + '/pdf', httpOptions).pipe(
			map((data: any[]) => data.map((item: any) => new Pdf(item.vvh460Dicsub02D208,
					item.vvh460DocLetra, item.vvh460DocPvta, item.vvh460DocNroco, item.vvh460PdfDoc)
				)
			), catchError(this.handleError)
		);
}
	
}
