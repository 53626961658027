export const TOKEN_EXPIRATION_TIME = 'tokenExpirationTime';
export const ID_TOKEN_USERID = 'idTokenUserid';
export const ACCESS_TOKEN_USERID = 'accessTokenUserid';
export const ID_TOKEN_JWT = 'idTokenJwt';
export const ACCESS_TOKEN_JWT = 'accessTokenJwt';
export const USER_LOGGED = 'user';
export const CLIENT_SELECTED = 'client';
export const CLIENT_TEMPORAL = 'temporalClient';
export const COUNTRY = 'country';

