import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Invoice } from '../models/invoice.model';
import { Vehicle } from '../models/vehicle.model';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { RetailReportsErrorHandler } from 'src/app/core/permissions/retailReportsErrorHandler';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { VehicleService } from '../services/vehicle.service';
import { ExportPDFService } from '../services/exportPDF/export-pdf.service';
import { SecurityService } from 'src/app/core/permissions/security.service';
import { InvoiceService } from '../services/invoice-service';
import { ExportCSVService } from '../services/exportCSV/export-scv.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

/** Required for pdf export */
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

/** This component is used to display the full list of detalleVentas */
@Component({
  templateUrl: './dealer-stock-list-page.component.html',
  styleUrls: ['./dealer-stock-list-page.component.css'],
})
export class DealerStockListPageComponent {
  tableContent$: Observable<any[]>;
  msgs: any[];
  cargaFacturaForm: FormGroup;
  vehicles: Array<Vehicle> = [];
  cols: any[];
  flag: boolean;
  first: number;
  numOfRows: number;
  rowNumSelectorOptions: { label: string; value: number }[];
  loading: boolean;
  display: boolean;
  totalRecords: number;
  sucursales: { label: string; value: number }[];
  selectedDealer: string;
  selectedVin: string;
  selectedDate: Date;
  valuni: number;
  montototal: number;
  accesfact: number;

  @ViewChild('detalleVentaTable') detalleVentaTable: any;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private security: SecurityService,
    private retailReportsError: RetailReportsErrorHandler,
    private vehicleService: VehicleService,
    private invoiceService: InvoiceService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private exportPDFService: ExportPDFService,
    private exportCSVService: ExportCSVService,
    public authService: AuthService
  ) {
    this.cargaFacturaForm = this.fb.group({
      selectedDealer: new FormControl(''),
      selectedVin: new FormControl(''),
      selectedDate: new FormControl(this.selectedDate),
      nrofactura: new FormControl(),
      montototal: new FormControl(this.montototal),
      accesfact: new FormControl(this.accesfact),
      valunidad: new FormControl(this.valuni),
    });

    this.sucursales = [
      { label: 'sucursal 1', value: 1 },
      { label: 'sucursal 2', value: 2 },
      { label: 'sucursal 3', value: 3 },
    ];

    this.cols = [
      { field: 'vin', header: 'Vin', filterMatchMode: 'contains' },
      { field: 'dealer', header: 'Concesionario', filterMatchMode: 'contains' },
      {
        field: 'shortDescription',
        header: 'Descripci\u00F3n',
        filterMatchMode: 'contains',
      },
    ];

    this.numOfRows = 10;
    this.loading = true;
    this.rowNumSelectorOptions = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];
  }

  /** gets the index of the last displayed row of data */
  getLastRowIndex(): number {
    if (
      this.detalleVentaTable.first + this.detalleVentaTable.rows >
      this.detalleVentaTable.totalRecords
    ) {
      return this.detalleVentaTable.totalRecords;
    } else {
      return this.detalleVentaTable.first + this.detalleVentaTable.rows;
    }
  }

  /** exports detalleVenta data, either all data or just selection, based on the option specified
   *
   * @param {{selectionOnly: boolean}} options determines whether to export all data or just the data that is selected
   */
  exportPDF(options?: { selectionOnly: boolean }) {
    const tableContent: any[][] = [];

    tableContent.push([
      { text: 'Vin', bold: true },
      { text: 'Concesionario', bold: true },
      { text: 'Descripcion', bold: true },
    ]);

    this.tableContent$ = this.vehicleService.get(0, 1000).pipe(
      tap((d: any) => {
        const tableContent: any[] = [];
        (<Vehicle[]>d.data).forEach((vehicle) => {
          tableContent.push([
            vehicle.vin,
            vehicle.dealerCode,
            vehicle.shortDescription,
          ]);
        });
        this.exportPDFService.exportPDF(tableContent, 'detalle-ventas.pdf');
      })
    );
  }

  /**
   * export complet CSV
   */
  exportCSV() {
    const tableContent: any[] = [];

    tableContent.push(['Vin', 'Concesionario', 'Descripcion']);

    this.vehicleService.get(0, 1000).subscribe({
      next: (d) => {
        (<Vehicle[]>d.data).forEach((vehicle) => {
          tableContent.push([
            vehicle.vin,
            vehicle.dealerCode,
            vehicle.shortDescription,
          ]);
        });
        this.exportCSVService.exportCSV(tableContent, 'detalle-ventas.csv');
      },
      error: (error) => {
        this.retailReportsError.handleError(error);
      },
    });
  }

  /**
   *
   * @param change
   */
  loadData(event?: LazyLoadEvent, change?: boolean) {
    this.loading = true;
    let total_pages = Math.round(this.totalRecords / this.numOfRows);

    let page = Math.round(
      (event?.first || + this.numOfRows) / this.numOfRows - 1
    );
    if (page == total_pages && change) {
      page = total_pages - 1;
    }
    if (this.numOfRows > this.totalRecords && change) {
      page = 0;
    }
    this.vehicleService.get(page, this.numOfRows).subscribe({
      next: (d) => {
        if (change) {
          this.detalleVentaTable.first = d.page * this.numOfRows;
        }
        this.vehicles = <Vehicle[]>d.data;
        this.totalRecords = d.totalItems;
        this.loading = false;
      },
      error: (error) => {
        this.retailReportsError.handleError(error);
      },
    });
  }

  opendialog(dealer: string, vin: string) {
    this.display = true;
    this.selectedVin = vin;
    this.selectedDealer = dealer;
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate());
    this.cargaFacturaForm = this.fb.group({
      selectedDealer: new FormControl(dealer),
      selectedVin: new FormControl(vin),
      selectedDate: new FormControl(this.selectedDate, Validators.required),
      montototal: new FormControl(this.montototal),
      accesfact: new FormControl(this.accesfact),
      valunidad: new FormControl(this.valuni, Validators.required),
    });
  }

  onSubmit(datosFactForm: any) {
    let r: Invoice[] = [];
    let d = new Invoice();

    d.dealerCode = datosFactForm.selectedDealer;
    d.country = this.authService.getClient().country;
    //d.retailPrice = parseFloat(datosFactForm.valunidad) + parseFloat(datosFactForm.accesfact);
    d.retailPrice = datosFactForm.valunidad;
    d.saleDate = datosFactForm.selectedDate;
    d.vin = datosFactForm.selectedVin;

    //d.vin = 'AE34RF54GF67JH6';
    r.push(d);

    this.invoiceService.addRetail(r).subscribe({
      next: (data) => {
        let details = '';
        data.forEach((e) => {
          details += e.vin + ', ' + e.message + '\r\n';
        });
        this.display = false;
        this.loadData();
        this.messageService.add({ detail: details, life: 3000000 });
      },
      error: (error) => {
        this.retailReportsError.handleError(error);
      },
    });
  }
}
