<div class="grid p-fluid" style="margin: 0 10px;">
<h2>Detalle Venta y Factura</h2>
</div>

<br/><br/>

<p-table #detalleVentaTable [value]="vehicles" [responsive]="true" [rows]="numOfRows"
		 [columns]="cols" [lazy]="true" (onLazyLoad)="loadData($event, false)"
		 [paginator]="true" [totalRecords]="totalRecords" [loading]="loading">

	<ng-template pTemplate="caption">
		<div class="button-container p-d-flex p-jc-between">
			<div class="ui-g-4 ui-sm-12 text-left">
				<label for="rowNumSelectorFoot">
					Resultados Por Página:
					<p-dropdown id="rowNumSelectorFoot" (onChange)="loadData($event, true)"  [options]="rowNumSelectorOptions" [(ngModel)]="numOfRows"
								ngDefaultControl></p-dropdown>
				</label>
			</div>
			<div class="ui-g-4 ui-sm-12 text-center vertical-center" *ngIf="vehicles.length>0">
				<p class="text-center vertical-center">Mostrando <span class="nowrap">{{detalleVentaTable.first + 1}}
					- {{getLastRowIndex()}} de {{totalRecords}}</span></p>
			</div>
			<div class="ui-helper-clearfix ui-g-4 ui-sm-12" *ngIf="vehicles.length>0">
				<div class="button-container1 p-d-flex">
					<button type="button" pButton icon="fas fa-file-excel" iconPos="left" label="Exportar a Excel"
							id="export" (click)="exportCSV()" class="p-button-rounded p-button-smaller"></button>
					<button type="button" pButton icon="fas fa-file-pdf" iconPos="left" label="Exportar a PDF"
							id="exportPDF" (click)="exportPDF()" class="p-button-rounded p-button-smaller"></button>
			</div>
		</div>

		</div>
	</ng-template>

	<ng-template pTemplate="header" let-columns>
		<tr>
			<th *ngFor="let col of columns" >{{col.header}}</th>
			<th style="width: 8em">Acciones</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-vehicle let-columns="columns">
		<tr>
			<td>{{vehicle.vin}}</td>
			<td>{{vehicle.dealerCode}}</td>
			<td>{{vehicle.shortDescription}}</td>
			<td class="text-center">
				<button style="min-width:160px;" label="Cargar Venta" class="p-button-rounded p-button-smaller" type="button" pButton icon="pi pi-file" (click)="opendialog(vehicle.dealerCode, vehicle.vin)"></button>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="summary">
		<div class="button-container p-d-flex">
			<div class="ui-g-4 ui-sm-12 text-left">
				<label for="rowNumSelectorFoot" >
					Resultados Por Página:
					<p-dropdown id="rowNumSelectorFoot"  (onChange)="loadData($event, true)" [options]="rowNumSelectorOptions" [(ngModel)]="numOfRows"
								ngDefaultControl></p-dropdown>
				</label>
			</div>

			<div class="ui-g-4 ui-sm-12 text-center vertical-center" *ngIf="vehicles.length>0">
				<p class="text-center vertical-center">Mostrando  <span class="nowrap">{{detalleVentaTable.first + 1}}
					- {{getLastRowIndex()}} de
					{{totalRecords}}</span></p>
			</div>

			<div class="ui-g-4 ui-sm-12" *ngIf="vehicles.length===0">
				No se encontraron datos
			</div>
			<div class="ui-g-4 ui-sm-12"></div>
		</div>
	</ng-template>
</p-table>

<p-dialog class="absolute-dialog"  header="Cargar Venta" [draggable]="true" [(visible)]="display" [modal]="true"
		  [contentStyle]="{'max-width':'600px','overflow': 'unset'}">
	<p-messages key="msgs"  [enableService]="true" [(value)]="msgs"></p-messages>
	<form [formGroup]="cargaFacturaForm" (ngSubmit)="onSubmit(cargaFacturaForm.value)">
		<div class="ui-g">
			<div class="ui-g-6">
				<div class="ui-g-12">
					<label for="sucursal">Concesionario</label>
				</div>
				<div class="ui-g-12">
					<input id="sucursal" formControlName="selectedDealer" type="text" pInputText [disabled]="true">
				</div>
			</div>
			<div class="ui-g-6">
				<div class="ui-g-12">
					<label for="vin">Vin</label>
				</div>
				<div class="ui-g-12">
					<input id="vin" type="text" formControlName="selectedVin" pInputText [disabled]="true">
				</div>
			</div>
		</div>

		<div class="ui-g">
			<div class="ui-g-6">
				<div class="ui-g-12">
					<label for="dateRange">Fecha Venta Retail: </label>
				</div>
				<div class="ui-g-12">
						<p-calendar id="dateRange"  [maxDate]="selectedDate"  dateFormat="yy-mm-dd" formControlName="selectedDate" placeholder="Requerido"></p-calendar>
				</div>
				<div class="ui-grid-col-12">
					<p-messages severity="error" text="La fecha es requerida"
							   *ngIf="!cargaFacturaForm.controls['selectedDate'].valid&&cargaFacturaForm.controls['selectedDate'].dirty"></p-messages>
				</div>
			</div>
			<div class="ui-g-6">
				<div class="ui-g-12">
					<label>Valor Unidad con Impuestos:</label>
				</div>
				<div class="ui-g-12">
					<div class="ui-inputgroup">
						<span class="ui-inputgroup-addon" style="width: 45px; line-height: 25px;">USD</span>						
						<input id="valunidad" type="text" maxlength="15.2" onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false"
							formControlName="valunidad" placeholder="Requerido"	pInputText pKeyFilter="pnum" pattern='^(\d+)(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$'>  
					</div>
				</div>
				<div class="ui-grid-col-12">
					<p-messages severity="error" text="Valor de la Unidad es requerido (ex. 9999.99)"
							   *ngIf="!cargaFacturaForm.controls['valunidad'].valid&&cargaFacturaForm.controls['valunidad'].dirty"></p-messages>
				</div>
		  </div>
		</div>
		<p-footer>
			<button type="submit" pButton icon="pi pi-check" [disabled]="!cargaFacturaForm.valid" label="Cargar Venta"></button>
			<button type="button" pButton icon="pi pi-times" (click)="display=false;" label="Cancelar"></button>
		</p-footer>
	</form>
</p-dialog>