import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  private requests: number = 0;
  public state: boolean = true;

  constructor() {}

  show() {
    if (this.state) {
      this.requests++;
    }
  }

  hide() {
    if (this.state) {
      this.requests--;
      if (this.requests < 0) {
        this.requests = 0;
      }
    }
  }

	shouldShow(): boolean {
		return this.state && this.requests > 0;
	}
}
