import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SparePartModel } from "../models/sparePart.model";
import { SparePartsService } from "../services/spare-parts.service";

@Component({
	selector: "app-documents-search",
	templateUrl: "./spare-parts.component.html",
	styleUrls: ["./spare-parts.component.css"],
})
export class SparePartsComponent implements OnInit {
	parameterParts: SparePartModel;

	partsForm = this.fb.group({
		openingDate: [null, Validators.required],
		closingDate: [null, Validators.required],
		generatingDate: [null, Validators.required],
	});

	constructor(
		private fb: FormBuilder,
		private sparePartsService: SparePartsService
	) {
		this.parameterParts = new SparePartModel();
	}

	resetFormGroup() {
	this.partsForm.reset();
	}

	ngOnInit() {
		this.sparePartsService.get().subscribe((response) => {
			this.parameterParts = new SparePartModel(response);
		});
	}

	save() {
		const parameterPartsToSave = new SparePartModel();
		parameterPartsToSave.openingDate =
			this.partsForm.get("openingDate")?.value;
		parameterPartsToSave.closingDate =
			this.partsForm.get("closingDate")?.value;
		parameterPartsToSave.generatingMix =
			this.partsForm.get("generatingDate")?.value;

		this.sparePartsService
			.set(parameterPartsToSave.format())
			.subscribe((response) => {
				this.parameterParts = new SparePartModel(response);
			});
		console.log();
	}
	
	toggleStatus() {
		this.sparePartsService.toggle(!this.parameterParts.status).subscribe((response) => {
			this.parameterParts = new SparePartModel(response);
		});
	}

	generateFileNow() {
		/*this.onSearch = true;
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, '0');
		const minutes = now.getMinutes().toString().padStart(2, '0');
		const seconds = now.getSeconds().toString().padStart(2, '0');

		const minutesInt = parseInt(minutes) + 10;
		now.setMinutes(minutesInt); // Ajustar correctamente los minutos

		const minutesString = now.getMinutes().toString().padStart(2, '0');
		const timeString = hours + ':' + minutesString + ':' + seconds;

		const dayString = new Date().toLocaleDateString('en-US', {day: '2-digit'});

		this.setParametersByDescripcion(dayString,"DIA_VENTAS_REPUESTOS");

		this.sparePartsService.setDownloadTime("VENTAS_REPUESTOS", "DESCARGA_PROGRAMADO_DATE", timeString, "Param., que indica a que hora se debe descargar el archivo de repuestos. Formato HH:mm:ss ").subscribe((response) => {
			console.log(response);
		});

		this.onSearch = false;
		window.location.reload();*/
	}

	changeClosingStatus() {
		/*this.onSearch = true;
		this.sparePartsService.setParameters("PERMITE_ENVIO_FILE_VENTAS_REPUESTOS", "true").subscribe(response => {
			console.log(response)
		});
		this.onSearch = false;
		window.location.reload();*/
	}
}
