<div class="my-overlay" *ngIf="getOnSearchDownload()">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>
<div class="grid p-fluid" style="margin: 0 10px;">
<h2>Documentos de Facturación</h2>
</div>

<p-messages [(value)]="msg" *ngIf="DateValid"></p-messages>
<form [formGroup]="documentSearchCriteria" (ngSubmit)="onSubmit(documentSearchCriteria.value)">
  <p>Criterio de Búsqueda:</p>

  <div class="p-grid p-fluid" style="display: flex; flex-wrap: wrap; justify-content: space-between;margin-top: 3rem; margin: 0 10px;">
    <!-- First five boxes (grouped in a row) -->
    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="typeDoc">Tipo de documento:</label>
      </div>
      <div class="p-col-6">
        <input type="text" pInputText pKeyFilter="alpha" id="typeDoc" formControlName="documentType"
               maxlength="4" style="width: fit-content; text-transform: uppercase"
               pTooltip="Si buscas:
           FC = Factura
           NC = Nota de credito
           ND = Nota de debito" tooltipEvent="focus">
      </div>
    </div>

    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="letterDoc">Letra del documento:</label>
      </div>
      <div class="p-col-6">
        <input type="text" pInputText pKeyFilter="alpha" id="letterDoc" formControlName="documentLetter"
               maxlength="1" style="width: fit-content; text-transform: uppercase">
      </div>
    </div>

    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="salePoint">Punto de venta:</label>
      </div>
      <div class="p-col-6">
        <input type="text" pInputText pKeyFilter="int" id="salePoint" formControlName="documentSalesPoint"
               maxlength="17" style="width: fit-content; text-transform: uppercase">
      </div>
    </div>
    
    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="docNumber">Nro del documento:</label>
      </div>
      <div class="p-col-6">
        <input type="text" pInputText pKeyFilter="int" id="docNumber" formControlName="documentNumber"
               maxlength="17" maxlength="10" style="width: fit-content; text-transform: uppercase">
      </div>
    </div>

    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="cuitNumber">Nro de CUIT:</label>
      </div>
      <div class="p-col-6">
        <input type="text" pInputText pKeyFilter="int" id="cuitNumber" formControlName="cuitNumber"
               maxlength="13" style="width: fit-content; text-transform: uppercase">
      </div>
    </div>

 
  </div>

  <!-- Last four boxes (aligned with the first five) -->
  <div class="p-grid p-fluid" style="display: flex; flex-wrap: wrap; justify-content: space-between;margin-top: 3rem; margin: 0 10px;">
    <div class="p-col-12 p-lg-6">
      <div class="p-col-12">
        <label for="fromDate">Desde:</label>
      </div>
      <div class="p-col-6">
        <p-calendar id="fromDate" formControlName="documentDateFrom" dateFormat="dd/mm/yy" [showIcon]="true">
        </p-calendar>
      </div>
    </div>

    <div class="p-col-12 p-lg-6">
      <div class="p-col-12">
        <label for="toDate">Hasta:</label>
      </div>
      <div class="p-col-6">
        <p-calendar id="toDate" formControlName="documentDateTo" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
      </div>
    </div>

    <div class="p-col-12 p-lg-6" (keydown.enter)="onSubmit(documentSearchCriteria.value)">
      <div class="p-col-12">
        <label for="nroVin">Nro de Vin</label>
      </div>
      <div class="p-col-6" pTooltip="Recordá que:
           Podés buscar por 17 dígitos: VIN completo.
           O podés buscar por 8 dígitos: número de serie." ptooltipEvent="focus">
        <p-autoComplete [(ngModel)]="vinSelected" [suggestions]="vins" (completeMethod)="getVINSforVehicle(vinSelected!)"
                        type="text" id="nroVin" formControlName="documentVinNumber">
        </p-autoComplete>
      </div>
    </div>

    <div class="p-col-12 p-lg-6">
      <div class="p-col-12">
        <label for="toDate">Origen:</label>
      </div>
      <div class="p-col-6">
        <p-dropdown [options]="optionsOrigin" name="seleccionar" formControlName="documentOrigin"></p-dropdown>
      </div>
    </div>
  </div>


  <div class="p-grid p-fluid" style="margin-top: 3rem;">

    <div class="p-col-12">
      <div class="button-container p-d-flex p-jc-between">
        <div class="button-container1 p-d-flex">
          <button pButton type="submit" label="Buscar Documento" class="p-button-rounded p-button-smaller"
                  pTooltip="No olvides utilizar algún filtro para tu búsqueda"></button>
          <button pButton type="button" (click)="resetFormGroup()" label="Limpiar campos"
                  class="p-button-rounded p-button-secondary p-button-smaller"
                  pTooltip="Se limpiarán todos los filtros"></button>
        </div>
        <div class="button-container2 p-d-flex">
          <button pButton type="button" (click)="downloadZipPdf()" label="Descargar seleccion"
                  class="p-button-rounded p-button-smaller"
                  pTooltip="Se descargarán todos los pdf seleccionados."></button>
          <button pButton type="button" (click)="downloadDocumentosPagina()" label="Descargar pagina"
                  class="p-button-rounded p-button-smaller"
                  pTooltip="Se descargarán todos los documentos de la página."></button>
          <button *ngIf="isIntern" pButton type="button" (click)="massiveDownload()" label="Descargar todos"
                  class="p-button-rounded p-button-smaller"
                  pTooltip="Se descargarán todos los documentos hallados sin importar la página."></button>
        </div>
      </div>
    </div>
  </div>

</form>
<div>
  <p-progressBar *ngIf="getOnSearch()" mode="indeterminate"></p-progressBar>

  <p-table #documentsTable [columns]="colums" [value]="documentList" [paginator]="true"
           [responsive]="true" [rows]="15" [lazy]="true" (onLazyLoad)="loadData($event)"
           [totalRecords]="totalOfDocs" id="documentsTable">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of colums">
          {{col.header}}
        </th>
        <th>
          <input type="checkbox" id="globalChek" (click)="selectedAllDocuments()"
                 style="width: 15px; height: 30px">
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-document let-colums="colums">
      <tr>
        <td style="text-align: center;">{{ getTypeOfDoc(document) }}</td>
        <td style="text-align: center;">{{ document.vvh460Dicsub02D208 }}</td>
        <td style="text-align: center;">{{ document.vvh460DocLetra }}</td>
        <td style="text-align: center;">{{ document.vvh460DocPvta }}</td>
        <td style="text-align: center;">{{ document.vvh460DocNroco }}</td>
        <td style="text-align: center;">{{ document.vvh460FemisDoc | date: 'dd/MM/yyyy' }}</td>
        <td style="text-align: center;">{{ document.vvh002NroVin }}</td>
        <td style="text-align: center;">{{ document.vvh402Cuit }}</td>
        <td style="text-align: center;">
          
          <div class="p-col-12">
            <div class="button-container p-d-flex p-jc-between">
              <div class="button-container2 p-d-flex">
            <div class="p-col-3">
              <button pButton type="button" label="Descargar" class="p-button-rounded"
                      (click)="downloadPdf(document)" id="download"></button>
            </div>
            <div class="p-col-3">
              <button pButton type="button" label="Abrir" class="p-button-rounded p-button-secondary"
                      (click)="showPdf(document)" id="open"></button>
            </div>
          </div>
        </div>
      </div>
    
        </td>
        <td style="text-align: center; margin-left: auto;">
          <div class="p-col-3">
            <p-checkbox name="pdf" [value]="document" [(ngModel)]="selectedDocuments"></p-checkbox>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>