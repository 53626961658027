export class Document {

//TODO: mapear con el nombre real que reciba del Json(NRO de vin sin nombre definido aun 2/13/2020)
	constructor(
		public vvh002NroVin: String,
		public vvh402Cuit: number,
		public vvh460Origen: string,
		public vvh460Dicsub02D208: string,
		public vvh460DocLetra: string,
		public vvh460DocPvta: number,
		public vvh460DocNroco: number,
		public vvh460FemisDoc: Date,
		public vvh460Status: string,
		public vvh460PdfDoc: string,
	) {}

    /** Creates a new document either with default values or the values of the object passed in
	 *
	 * @param {Document} document information to copy into new document object
	 */
	/*constructor(document?: Document) {
		this.vvh460Origen = '';
		this.vvh460Dicsub02D208 = '';
		this.vvh460DocLetra = '';
		this.vvh460DocPvta = 0;
		this.vvh460DocNroco = 0;
		this.vvh460FemisDoc = null;
		this.vvh460Status = '';
		this.vvh460PdfDoc = null;

		if (document) {
			Object.assign(this, document);
		}
	}
	*/


}
