import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ACCESS_TOKEN_JWT, ACCESS_TOKEN_USERID} from '../auth/auth-vars-constants';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-oauth-callback',
  template: `
    <h3>Redirecting...</h3>
  `,
  styles: []
})
export class OauthCallbackComponent implements OnInit {

  readonly idToken: string | null;
  readonly accessToken: string | null;
  readonly originUri: string | null;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    const searchParams = new URLSearchParams(route.snapshot.fragment == null ? undefined : route.snapshot.fragment);
    this.originUri = route.snapshot.queryParamMap.get('origin_uri');
    this.accessToken = searchParams.get('access_token');
    this.idToken = searchParams.get('id_token');
  }
  async ngOnInit() {
    if (this.accessToken != null) {
      this.authService.storeJWT(ACCESS_TOKEN_JWT, this.accessToken);
      this.authService.storeTokenExpirationTime(this.accessToken);
      await this.authService.storeUser(this.accessToken);

      this.router.navigate([`/${this.originUri}`]);
    } else {
      alert('Problem authenticating');
    }
  }
}
