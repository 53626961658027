import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {sparePartsResponseTO} from "../resolves/spare-parts.responseTO";
import {ParameterRequestTO} from "../resolves/parameter.request.TO";
import {DownloadParameterRequestTO} from "../resolves/downloadParameter.request.TO";
import {TimeParameterRequestTO} from "../resolves/timeParameter.request.TO";
import {DateParameterRequestTO} from "../resolves/dateParameter.request.TO";
import { SparePartModel } from '../models/sparePart.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { SparePartsModel } from '../models/spareParts.model';

@Injectable({
	providedIn: 'root'
})
export class SparePartsService {
	private url = environment.api + 'spare-parts';
	private sparePartsUrl = environment.api + 'documents' + '/spare-parts';
	private saveParametersUrl = environment.api + 'documents' + '/saveParameterValue';
	private saveFinalDateParameter = environment.api + 'documents' + '/saveDateTimeParameterValue';
	private saveTimeParameter = environment.api + 'documents' + '/setDownloadTime';
	private getTimeParameter = environment.api + 'documents' + '/getDownloadTime';


	parameters: SparePartsModel[];

	private options = {
		header: new HttpHeaders({
			'Content-Type': 'application/json'
		})
	}

	constructor(private http: HttpClient, private authService: AuthService ) {
		this.parameters = [];
	}

	get(): Observable<SparePartModel> {
		return this.http.get<SparePartModel>(this.url);
	}

	set(sparePartModel: SparePartModel): Observable<SparePartModel> {
		return this.http.post<SparePartModel>(this.url, sparePartModel);
	}

	toggle(status: boolean) {
		return this.http.put<SparePartModel>(`${this.url}?status=${status}`, {});
	}

	setDownloadTime(nombreParametro: string, subTipoParametro: string, valorParametro: string, descripcion: string): Observable<any> {

		let request = new DownloadParameterRequestTO();
		request.nombreParametro = nombreParametro;
		request.subTipoParametro = subTipoParametro;
		request.valorParametro = valorParametro;
		request.descripcion = descripcion;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
		};
		return this.http.post<any>(this.saveTimeParameter,request, httpOptions);
	}

	setParameters(nombreParametro: string, valorParametro: string): Observable<any> {

		let request = new ParameterRequestTO();
		request.nombreParametro = nombreParametro;
		request.valorParametro = valorParametro;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
		};
		return this.http.post<any>(this.saveParametersUrl,request, httpOptions);
	}

	setParametersByDescription(nombreParametro: string, valorParametro: string, descripcionParametro: string): Observable<any> {

		let request = new DateParameterRequestTO();
		request.nombreParametro = nombreParametro;
		request.valorParametro = valorParametro;
		request.descripcionParametro = descripcionParametro;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
		};
		return this.http.post<any>(this.saveFinalDateParameter,request, httpOptions);
	}


	getParameters(nombreParametroUno: string, nombreParametroDos: string,
				  nombreParametroTres: string, nombreParametroCuatro: string,
				  nombreParametroCinco: string, nombreParametroSeis: string): Observable<sparePartsResponseTO> {
		let httpParameters = new HttpParams();
		if (nombreParametroUno && nombreParametroDos) {
			httpParameters = httpParameters.set('nombreParametroUno', nombreParametroUno);
			httpParameters = httpParameters.set('nombreParametroDos', nombreParametroDos);
			httpParameters = httpParameters.set('nombreParametroTres', nombreParametroTres);
			httpParameters = httpParameters.set('nombreParametroCuatro', nombreParametroCuatro);
			httpParameters = httpParameters.set('nombreParametroCinco', nombreParametroCinco);
			httpParameters = httpParameters.set('nombreParametroSeis', nombreParametroSeis);
		}

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParameters,
		};

		return this.http.get<sparePartsResponseTO>(this.sparePartsUrl, httpOptions);
	}

	getTimeParameters(nombreParametro: string, subTipoParametro: string, descripcion: string): Observable<TimeParameterRequestTO> {
		let httpParameters = new HttpParams();
		if (nombreParametro && subTipoParametro) {
			httpParameters = httpParameters.set('nombreParametro', nombreParametro);
			httpParameters = httpParameters.set('subTipoParametro', subTipoParametro);
			httpParameters = httpParameters.set('descripcion', descripcion);
		}

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Client': this.authService.getClient().code.toString(),
				'Country': this.authService.getClient().country,
			}),
			params: httpParameters,
		};

		return this.http.get<TimeParameterRequestTO>(this.getTimeParameter, httpOptions);
	}



	handleError(error : any) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			errorMessage = 'No se ha podido cambiar las fechas, por favor avisarle a "fishelp@ford.com"';
		} else {
			errorMessage = 'No se ha podido cambiar las fechas, por favor avisarle a "fishelp@ford.com"';
		}
		window.alert(errorMessage);
		return throwError(errorMessage);
	}
}