import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'app-unauthorize',
	templateUrl: './unauthorize-page.component.html',
	styleUrls: ['./unauthorize-page.component.css'],
})
export class UnauthorizeComponent implements OnInit {

	severity!: string;
	title!: string;
	instructions1!: string;
	instructions2!: string;
	buttonLabel?: string;
	url!: string;

	constructor(private route: ActivatedRoute) {}

	ngOnInit() {
		this.route.queryParams
			.subscribe(params => {
				this.severity = params['severity'];
				this.title = params['title'];
				this.instructions1 = params['instructions1'];
				this.instructions2 = params['instructions2'];
				this.buttonLabel = params['buttonLabel'];
				this.url = params['url'];
			});
	}

	goToUrl() {
		window.location.href = this.url;
	}
}
