import {
  Component, EventEmitter, HostListener, OnInit, Output
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Client } from 'src/app/shared/model/client.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  /** used to show mobile menu */
  hamMenu: boolean = false;
  /** Emits an event when the menu is closed when it is in hamburger menu mode */
  @Output() closeClicked = new EventEmitter<boolean>();
  /** emits an event calling functions in parent */
  @Output() parentEvent = new EventEmitter<string>();
  /** Array of PrimeNG MenuItems that are used to populate the p-menubar */
  documentVisible: boolean = false;

  navMenuItems: MenuItem[] = [];

  user: User | undefined;
  dealers: Client[] = [];
  dealer: Client | undefined;
  selectedDealer: Client = new Client();

  /** client modal variables */
  display: boolean = false;

  constructor(public authService: AuthService) {}

  ngOnInit() {
    this.user = this.authService.getUser();
    this.dealers = this.user.clients;
    this.dealer = this.authService.getClient();
    this.navMenuItems = [
      {
        label: 'Stock Concesionario',
        routerLink: '/dealer-stock-list',
        command: this.closeHamMenu,
        visible: this.authService.asRole('FDP01001'),
      },
      {
        label: 'Documentos',
        routerLink: '/documents-search',
        command: this.closeHamMenu,
        visible: this.authService.asRole('FDD00044'),
      },
      {
        label: 'Repuestos',
        routerLink: '/spare-parts',
        command: this.closeHamMenu,
        visible: this.authService.asRole('FDI00003'),
      },
    ];
    this.authService.navMenuItems = this.navMenuItems;
  }

  /** Anonymous function that is used to close the hamburger menu */
  closeHamMenu = () => {
    this.closeClicked.emit(false);
  };

  /**
   * toggle dealer dialog
   */
  showDealerToggle() {
    this.selectedDealer = this.authService.getClient();
    this.display = !this.display;
  }

  /**
   * listener to toggle hamburger menu status
   * @param event
   */
  @HostListener('window:resize', ['$event']) onResize(event?: any) {
    if (event.target.innerWidth > 767) {
      this.hamMenu = false;
    }
  }

  /**
   * call logout service clear and remove token
   */
  logout() {
    this.authService.logout();
  }

  /**
   * change delaer selected for work
   */
  changeDealer() {
    this.authService.setClient(this.selectedDealer);
    location.reload();
  }
}
