import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role } from 'src/app/shared/model/role.model';
import { environment } from 'src/environments/environment';
import { Client } from 'src/app/shared/model/client.model';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private url = environment.api + 'security';

  private options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  /**
   * this method return true or false, to verify Role access
   * @param role
   */
  asRole(role: String): Observable<Role> {
    // TODO: change this to PathParam
    let body = {
      //code: this.authService.getClient()?.code,
      //country: this.authService.getClient()?.country,
      //type: this.authService.getClient()?.type,
      code: null,
      country: null,
      type: null,
    };
    return this.http.post<Role>(`${this.url}/role/${role}`, body, this.options);
  }

  /**
   * this method return list of roles for user
   */
  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.url, this.options);
  }

  /**
   * get a client list for the user logged, includes roles
   */
  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.url}/clients`, this.options);
  }
}
