import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { Client } from 'src/app/shared/model/client.model';
import { User } from 'src/app/shared/model/user.model';
import { SecurityService } from '../permissions/security.service';
import { environment } from 'src/environments/environment.prod';
import {
  ACCESS_TOKEN_JWT,
  ACCESS_TOKEN_USERID,
  TOKEN_EXPIRATION_TIME,
  USER_LOGGED,
  CLIENT_SELECTED,
  CLIENT_TEMPORAL,
} from './auth-vars-constants';

@Injectable()
export class AuthService {
  navMenuItems: MenuItem[] = [];
  sessionStorage: Storage;
  cleanOriginUri: string;
  moduleMappings: any;

  constructor(private securityService: SecurityService) {
    this.sessionStorage = sessionStorage;
  }

  isLoggedIn(): boolean {
    return moment().isBefore(
      moment(this.sessionStorage.getItem(TOKEN_EXPIRATION_TIME), 'X')
    );
  }

  retrieveCDSID(): string {
    return this.getJwtData()['CommonName'];
  }

  isGenericId(): boolean {
    return this.getJwtData()['empcode'] === 'G';
  }

  storeJWT(jwtTokenKeyId: string, token: string): void {
    this.sessionStorage.setItem(jwtTokenKeyId, token);
  }

  storeTokenExpirationTime(token: string): void {
    const payload = atob(token.split('.')[1]);
    this.sessionStorage.setItem(TOKEN_EXPIRATION_TIME, JSON.parse(payload).exp);
  }

  storeUser(token: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const payload = atob(token.split('.')[1]);
      const payloadObject = JSON.parse(payload);
      let user = new User();
      user.userName = payloadObject.CommonName;
      user.displayName = payloadObject.fordDisplayName
        ? payloadObject.fordDisplayName
        : `${payloadObject.surname}, ${payloadObject.givenname} (${payloadObject.initials})`;
      user.userType = payloadObject.fordUserType;
      user.country = payloadObject.c;

      this.sessionStorage.setItem(USER_LOGGED, JSON.stringify(user));
      this.setURL();
      this.securityService.getClients().subscribe({
        next: (data) => {
          user.clients = data;
          const temporalClient = this.getTemporalClient();

          if (temporalClient || temporalClient !== null) {
            const selectedClient =
              user.clients.find(
                (client: any) => client.code === +temporalClient.client
              ) || user.clients[0];

            if (selectedClient) {
              this.setClient(selectedClient);
            }
            this.clearTemporalClient();
          } else {
            const selectedModule = this.moduleMappings[this.cleanOriginUri];
            const foundClient =
              user.clients.find((c) =>
                c.roles.some((role) => role.module.includes(selectedModule))
              ) || user.clients[0];
            this.setClient(foundClient);
          }

          this.sessionStorage.setItem(USER_LOGGED, JSON.stringify(user));
          resolve();
        },
        error: (data) => {
          console.log('error', data);
          reject();
        },
      });
    });
  }

  retrieveJwtToken(): string {
    return <string>this.sessionStorage.getItem(ACCESS_TOKEN_JWT);
  }

  getBearerToken(): string {
    return `Bearer ${this.retrieveJwtToken()}`;
  }

  private getJwtData(): any {
    const idTokenString = this.sessionStorage.getItem(ACCESS_TOKEN_USERID);
    if (idTokenString) {
      return JSON.parse(idTokenString);
    }
    return {};
  }

  getUser(): User {
    return JSON.parse(<string>this.sessionStorage.getItem(USER_LOGGED));
  }

  getClient(): Client {
    return JSON.parse(<string>this.sessionStorage.getItem(CLIENT_SELECTED));
  }

  setClient(client: Client) {
    sessionStorage.setItem(CLIENT_SELECTED, JSON.stringify(client));
  }

  setTemporalClient(temporalClient: any) {
    sessionStorage.setItem(CLIENT_TEMPORAL, JSON.stringify(temporalClient));
  }

  getTemporalClient() {
    return JSON.parse(<string>sessionStorage.getItem(CLIENT_TEMPORAL));
  }

  clearTemporalClient() {
    sessionStorage.removeItem(CLIENT_TEMPORAL);
  }

  asRole(role: string): boolean {
    return this.getClient()?.roles?.some((e) => e.module == role);
  }

  logout(): void {
    this.sessionStorage.removeItem(ACCESS_TOKEN_JWT);
    this.sessionStorage.removeItem(TOKEN_EXPIRATION_TIME);
    this.sessionStorage.removeItem(USER_LOGGED);
    this.sessionStorage.removeItem(CLIENT_SELECTED);
    window.location.replace(environment.logoutUrl);
  }

  setURL() {
    const callbackResponse = document.URL.split('?')[1];
    if (callbackResponse) {
      const parts = callbackResponse.split('#access_token=');
      if (parts.length >= 2) {
        this.cleanOriginUri = parts[0].replace('origin_uri=%2F', '');
        console.log(this.cleanOriginUri);
      }
    }
    this.moduleMappings = {
      'documents-search': 'FDD00044',
      'dealer-stock-list': 'FDP01001',
      'spare-parts': 'FDI00003',
    };
  }
}
