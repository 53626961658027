import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class RetailReportsErrorHandler {
  danger = 'fa fa-minus-circle danger';
  warning = 'fa fa-exclamation-triangle warning';

  constructor(private router: Router, private messageService: MessageService) {}

  public handleError(error: any) {
    const httpErrorCode = error.status;

    switch (httpErrorCode) {
      case 401:
        this.router.navigate(['unauthorize'], {
          skipLocationChange: true,
          queryParams: {
            severity: this.danger,
            title: 'Acceso Denegado',
            instructions1: 'Usted no está autorizado a acceder a este modulo',
            instructions2:
              'Si necesita acceso, por favor, consulte con su administrador.',
						buttonLabel: 'Ok',
						url: '/'
          },
        });
        break;
      case 504:
        this.router.navigate(['unauthorize'], {
          skipLocationChange: true,
          queryParams: {
            severity: this.danger,
            title: 'Tiempo de espera agotado',
            instructions1: 'El servidor no responde',
            instructions2: 'Contacte a su administrador CSA',
          },
        });
        break;
      case 500:
        this.messageService.add({
          severity: 'error',
          summary: 'Error interno en el servidor',
          detail: 'El servidor tuvo un problema al procesar los datos',
        });
        break;
      case 700:
        this.router.navigate(['unauthorize'], {
          skipLocationChange: true,
          queryParams: {
            severity: this.warning,
            title: 'Sin Concesionario Asignado',
            instructions1:
              'Usted no tiene asignado ningún concesionario para operar en FisDealer',
            instructions2:
              'Si necesita una asignación, por favor, contacte al administrador del sistema',
          },
        });
        break;
      default:
        this.messageService.add({
          severity: 'error',
          summary: 'Error General',
          detail: 'Algo no salió bien. contacte al administrador del sistema.',
        });
    }
  }
}
