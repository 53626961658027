import { Client } from "./client.model";

export class User {

	userName: string;
	userType: string;
	displayName: string;
	country: string;
	clients: Client[];

	constructor(user?: User) {
		this.userName = '';
		this.userType = '';
		this.displayName = '';
		this.country = '';
		this.clients = [];

		if (user) {
			Object.assign(this, user);
		}
	}
}
