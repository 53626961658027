<div class="jumbotron text-center" style="font-size: 130%; padding: 50px;">
    <h2 id="noAccess">
        <span class="{{severity}}"></span>
            {{title}}
    </h2>
    <p>{{instructions1}}.</p>
    <p>{{instructions2}}</p>
    <p *ngIf="buttonLabel">
        <button pButton type="button" label="{{buttonLabel}}" class="ui-button-success" (click)="goToUrl()"></button>
    </p>
    <br>
</div>