export class Invoice {

	dealerCode: number;
	country: string;
	retailPrice: number;
	saleDate: string;
	vin: string;

	/** Creates a new invoice either with default values or the values of the object passed in
	 *
	 * @param {Invoice} invoice detalleVenta information to copy into new invoice object
	 */
	constructor(invoice?: Invoice) {

		this.dealerCode = 0;
		this.country = '';
		this.retailPrice = 0;
		this.saleDate = '';
		this.vin = ''

		if (invoice) {
			Object.assign(this, invoice);
		}
	}
}
