import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { RetailReportsErrorHandler } from './retailReportsErrorHandler';

@Injectable()
export class SecurityGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private authService: AuthService,
    private readonly errorHandler: RetailReportsErrorHandler
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const asRole = this.authService.asRole(next.data['role']);
    if (!asRole) {
      this.errorHandler.handleError({ status: 401 });
    }

    return asRole;
  }
}
