import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  template: `
    <h3>Redirecting to login server</h3>
  `,
  styles: []
})
export class LoginComponent implements OnInit {

  location: Location = window.location;

  constructor(private router: ActivatedRoute) {
  }

  ngOnInit() {
    const originUri = this.router.snapshot.paramMap.get('origin_uri');
    this.location.href = `${environment.ssoEndpointUri}?client_id=${environment.ssoClientId}&response_type=token&resource=
    ${environment.ssoResourceId}&redirect_uri=${this.getSSORedirectUri()}?origin_uri=${originUri}`;
  }

  getSSORedirectUri() {
    let redirect = '/access_token';
    const origin = window.location.protocol + '//' + window.location.host;
    return origin + encodeURI(redirect);
  }

}
