<div>
	<div class="grid p-fluid" style="margin: 0 20px;">
	<h2>Configuración de Parámetros</h2>
	</div>

	<div class="grid p-fluid" style=" flex-wrap: wrap; justify-content: space-between; margin: 0 20px;">
		<div class="conteiner-1">
			<label for="openingDate">Fecha Programada de Apertura del Sistema:</label>
			<p-calendar id="openingDate" [readonlyInput]="true" [showTime]="true" [(ngModel)]="parameterParts.openingDate"
				[disabled]="true"></p-calendar>
		</div>

		<div class="conteiner-1" >
			<label for="status">Estado del Sitio:</label>
			<input type="text" pInputText pKeyFilter="alpha" [(ngModel)]="parameterParts.status" id="status" readonly="true"
				[readOnly]="true" [disabled]="true">
		</div>

		<div class="conteiner-1">
			<label for="lastParameterDate">Fecha Programada de Generación del Consolidado:</label>
			<p-calendar id="lastParameterDate" [readonlyInput]="true" [(ngModel)]="parameterParts.generatingMix"
				[disabled]="true"></p-calendar>
		</div>
	</div>
	
	<div class="grid p-fluid" style=" flex-wrap: wrap; justify-content: space-between; margin: 0 20px;">
		<div class="conteiner-2">
			<label for="closingDate">Fecha Programada de Cierre del Sistema:</label>
			<p-calendar id="closingDate" [readonlyInput]="true" [showTime]="true" [(ngModel)]="parameterParts.closingDate"
				[disabled]="true"></p-calendar>
		</div>
		<div class="conteiner-2" >
			<label for="actualTimeSet">Hora Programada Para la Generación del Consolidado:</label>
			<input type="text" pInputText pKeyFilter="alpha" [(ngModel)]="parameterParts.generatingTime" id="actualTimeSet"
				readonly="true" [readOnly]="true" [disabled]="true">
		</div>
	</div>




	<form [formGroup]="partsForm" (ngSubmit)="save()" class="grid"
		style="grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 1rem;">

		<div>
		<div class="grid p-fluid" style="flex-wrap: wrap; margin-top: 3rem; margin: 0 12px;">
			<div class="conteiner-1">
				<label for="openingDateSet">Configurar Fecha de Inicio del Próximo Cierre:</label>
			<p-calendar id="openingDateSet" formControlName="openingDate" [required]="true" [showTime]="true"
				[showSeconds]="true" [stepMinute]="20"></p-calendar>
		</div>

		<div class="conteiner-1" style="margin-left: 16rem;">
			<label for="closingDateSet">Configurar Fecha de Cierre del Próximo Cierre:</label>
			<p-calendar id="closingDateSet" formControlName="closingDate" [required]="true" [showTime]="true"
				[showSeconds]="true" [stepMinute]="20"></p-calendar>
		</div>

		<div class="conteiner-1" style="margin-left: 14rem;">
			<label for="generatingDateSet">Configurar Fecha del Consolidado del Archivo:</label>
			<p-calendar id="generatingDateSet" formControlName="generatingDate" [required]="true" [showTime]="true"
				[showSeconds]="true" [stepMinute]="20"></p-calendar>
		</div>
	</div>
</div>

			<div class="p-col-12">
			  <div class="button-container p-d-flex p-jc-between">
				<div class="button-container1 p-d-flex">

					<button pButton type="button" (click)="toggleStatus()" label="Cambiar Estado del Sitio"
					class="p-button-rounded p-button-smaller" pInputText
						pTooltip="Se abrirá o cerrará (permitirá el envío de archivos o no) el sitio dependiendo del estado actual.">
					</button>

					<button pButton type="submit" label="Enviar parámetros" class="p-button-rounded p-button-smaller" pInputText
						pTooltip="No olvides insertar la fecha."></button>
			</div>
		</div>
		</div>
		</form>
