import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { OauthCallbackComponent } from './core/oauth-callback/oauth-callback.component';
import { LandingPageComponent } from './core/permissions/landing-page.component';
import { SecurityGuard } from './core/permissions/security.guard.service';
import { LoginComponent } from './login/login.component';
import { DealerStockListPageComponent } from './modules/dealer-stock/dealer-stock-list-page.component';
import { DocumentsSearchComponent } from './modules/documents-search/documents-search.component';
import { SparePartsComponent } from './modules/spare-parts/spare-parts.component';
import { UnauthorizeComponent } from './modules/unauthorize-page/unauthorize-page.component';
const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'access_token', component: OauthCallbackComponent },
	{
    path: 'unauthorize',
    component: UnauthorizeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dealer-stock-list',
    component: DealerStockListPageComponent,
    canActivate: [AuthGuard, SecurityGuard],
    data: { role: 'FDP01001' },
  },
  {
    path: 'documents-search',
    component: DocumentsSearchComponent,
    canActivate: [AuthGuard, SecurityGuard],
    data: { role: 'FDD00044' },
  },
  {
    path: 'spare-parts',
    component: SparePartsComponent,
    canActivate: [AuthGuard, SecurityGuard],
    data: { role: 'FDI00003' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
