import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth/auth.guard';
import { AuthService } from './core/auth/auth.service';
import { HttpRequestInterceptor } from './core/interceptor/http-request-interceptor';
import { OauthCallbackComponent } from './core/oauth-callback/oauth-callback.component';
import { HeaderComponent } from './core/template/header/header.component';
import { LandingPageComponent } from './core/permissions/landing-page.component';
import { LoginComponent } from './login/login.component';
import { DatePipe } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { LoaderComponent } from './core/loader/loader.component';
import { LoaderService } from './core/loader/loader.service';
import { LoaderInterceptorService } from './core/loader/loader-interceptor';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { DocumentsSearchComponent } from './modules/documents-search/documents-search.component';
import { TableModule } from 'primeng/table';
import {ProgressBarModule} from 'primeng/progressbar';
import { VehicleService } from './modules/services/vehicle.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SparePartsComponent } from './modules/spare-parts/spare-parts.component';
import { SparePartsService } from './modules/services/spare-parts.service';
import { SecurityGuard } from './core/permissions/security.guard.service';
import { UnauthorizeComponent } from './modules/unauthorize-page/unauthorize-page.component';
import { RetailReportsErrorHandler } from './core/permissions/retailReportsErrorHandler';
import { DealerStockListPageComponent } from './modules/dealer-stock/dealer-stock-list-page.component';
import { InvoiceService } from './modules/services/invoice-service';
import { VehicleListResolve } from './modules/resolves/vehicle-list.resolve';

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OauthCallbackComponent,
    LoaderComponent,
    HeaderComponent,
    LandingPageComponent,
    DocumentsSearchComponent,
    SparePartsComponent,
    DealerStockListPageComponent,
    UnauthorizeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MenubarModule,
    SidebarModule,
    DialogModule,
    ListboxModule,
    SelectButtonModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    MessagesModule,
    AutoCompleteModule,
    DropdownModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    ProgressBarModule,
  ],
  providers: [
    AuthGuard,
    SecurityGuard,
    AuthService,
    LoaderService,
    HttpClientModule,
    DatePipe,
    VehicleService,
    VehicleListResolve,
    MessageService,
    SparePartsService,
    RetailReportsErrorHandler,
    InvoiceService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  
})

export class AppModule {}
